<template>
  <div class='relative'>
    <button
      @click='toggleStrategysFilters'
      class='uppercase text-sm text-left px-2 py-2 flex flex-row items-center gap-x-1 hover:bg-gray-50 border border-solid rounded border-transparent hover:border-gray-200 hover:shadow-lg'>
      <span class='inline-block w-48 truncate'>{{ filterStrategysString }}</span>
      ({{selectedStrategys.length}})
    </button>
    <div v-if='showStrategysFilters'
      class='absolute left-0 border rounded-lg shadow-lg z-20 bg-white'
      style='top: 2.5rem;'>
      <div class='w-full h-full relative pt-8 pb-4 pl-4 pr-4'>
        <button
          @click='closeStrategysFilters'
          class='absolute h-5'
          style='top: 0.5rem; right: 0.5rem;'>
        <x-icon class='inline-block text-gray-500 hover:text-gray-900 cursor-pointer h-4' />
        </button>
        <button
          v-if='selectedAll'
          @click='unselectAll'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2 whitespace-no-wrap'>
          Unselect All
        </button>
        <button
          v-if='!selectedAll'
          @click='selectAll'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2 whitespace-no-wrap'>
          Select All
        </button>
        <label v-for='(item, index) in selectableStrategys'
          :key='`select-item-dropdown-${index}-${item}`'
          class='pl-2 pr-12 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2 whitespace-no-wrap'>
          <input
            type='checkbox'
            v-model='selectedStrategys'
            :value='item' />
          <span class='whitespace-no-wrap'>{{ item }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon }      from '@vue-hero-icons/outline'
import cloneDeep      from 'lodash/cloneDeep'

export default {
  name: 'PortfolioStrategySelect',
  props: ['selectableStrategys'],
  components: {
    XIcon,
  },
  data () {
    return {
      savedFilter: [],
      showStrategysFilters: false,
      selectedStrategys: [],
    }
  },
  watch: {
    'selectableStrategys': {
      handler: function () {
        if (this.selectableStrategys.length > 0) {
          this.selectAll()
          this.$emit('done-strategys-select', this.selectedStrategys)
        }
      },
      immediate: true,
    },
  },
  computed: {
    filterStrategysString () {
      if (this.selectableStrategys.length === this.selectedStrategys.length) {
        return 'All Strategys'
      } else if (this.selectedStrategys.length === 0) {
        return 'Strategy 선택'
      } else {
        return this.selectedStrategys.toString()
      }
    },
    selectedAll () {
      return this.selectedStrategys.length === this.selectableStrategys.length
    }
  },
  methods: {
    toggleStrategysFilters () {
      this.showStrategysFilters = !this.showStrategysFilters
    },
    closeStrategysFilters () {
      this.showStrategysFilters = false
      this.doneStrategysSelect()
    },
    selectAll () {
      this.selectedStrategys = cloneDeep(this.selectableStrategys)
    },
    unselectAll () {
      this.selectedStrategys = []
    },
    doneStrategysSelect () {
      this.$emit('done-strategys-select', this.selectedStrategys)
    }
  }
}
</script>
